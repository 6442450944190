.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: #f3eaff; /* 연한 보라색 */
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
}

.modalHeader {
    display: flex;
    justify-content: flex-end;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; /* 상단 여백 추가 */
}

.albumArt {
    width: 20%; /* 이미지 크기 조정 */
    border-radius: 10px;
    margin-bottom: 10px; /* 여백 추가 */
}

.trackDetails {
    width: 100%;
    text-align: center;
}

.artist {
    color: #A0A1A4;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    width: 100%; /* 컨테이너 전체 너비 사용 */
}


.button {
    display: flex;
    align-items: center; /* 아이템들을 수직 중앙 정렬 */
    justify-content: center; /* 아이템들을 수평 중앙 정렬 */
    margin-top: 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #000;
}

.icon1, .icon2 {
    margin-right: 8px; /* 아이콘과 텍스트 사이의 간격 */
    width: 22px;
    height: 22px;
}


.moreOptions {
    margin-top: 15px;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: #7b61ff; /* 동일한 버튼 스타일 적용 */
    border: none;
    cursor: pointer;
    color: white; /* 텍스트 색상 */
}
