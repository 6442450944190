.trackContainer {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 10px;
}

.trackInfoContainer {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
}


.coverImg {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
}

.trackInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-grow: 1;
}

.trackName {
    color: #383838;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.albumArtist {
    color: #A0A1A4;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.etcContainer {
    margin-left: auto;
    margin-right: 10px;
}

.subtraction {
    cursor: pointer;
}