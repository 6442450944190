/* 기존의 .reviewBtn, .writeReview 등의 스타일은 유지 */

.contentContainer {
    background-color: #FFFFFF;
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.albumArtContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.albumArt {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 4px;
}

.albumInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 35px;
}

.albumInfo > h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
    min-width: 0;
    
}

.albumInfo > h2 {
    font-size: 16px;
    font-weight: 400;
    color: #a0a1a4;
    padding: 0;
    margin: 0 0 10px 0;
}

.albumInfo > h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.ratingInfo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    color: rgb(0, 0, 0);
    padding: 10px 0;
    width: 100%;
    margin: 10px 0;
    margin-bottom: 25px;
}

.ratingItem {
    flex: 1; /* 모든 항목이 동일한 너비를 가지도록 설정 */
}

.value {
    font-size: 16px; /* 숫자 크기 */
    font-weight: bold; /* 굵은 글씨 */
    margin-bottom: 12px;
}

.label {
    font-size: 12px; /* 라벨 텍스트 크기 */
    color: #a0a1a4; /* 회색 */
}


.reviewButton {
    display: block;
    width: 100%;
    height: 51px;
    color: white;
    background-color: #7B61FF;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.reviewCompeleteButton{
    display: block;
    width: 100%;
    height: 51px;
    color: #383838;
    background-color: #EADDFF;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.socialButtons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    justify-content: space-around;
}

.socialButton {
display: flex;
align-items: center;
}

.socialIcon {
width: 20px; /* 아이콘 크기 조절 */
height: 24px;
}

.likeCount {
margin-left: 15px; /* 아이콘과 좋아요 수 간격 조절 */
font-size: 16px; /* 좋아요 수 글씨 크기 조절 */
}

.navBar {
    margin-top: 10px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    gap: 40px;
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #383838;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;
}

.trackItem {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding: 0;
    align-items: center;
}

.trackDuration {
    margin-right: 16px;
    color: #a0a1a4;
    font-size: 12px;
}

.starIcon {
    margin-right: 8px;
}

.trackRating {
    display: flex;
    align-items: center;

}

.trackListContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 24px 0 12px;
}


.trackNumber {
    color: #a0a1a4;
    margin-right: 26px;
    font-size: 16px;
    text-align: center;
    width: 16px;
}

.trackTitle {
    flex: 1;
    margin-right: 12px;
    font-size: 16px;
}

.activeTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #383838;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;
}

.indicator {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 5px;
    margin-top: 5px;
    transition: all 0.3s;
}

.activeTab .indicator {
    width: 30px;
    height: 3px;
    background: #7B61FF; /* 보라색 */
    border-radius: 5px;
}

.commentSection{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.sectionTitle{
    color: #808080;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sectionTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subSection {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
}
