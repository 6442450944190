.userName {
    color: #383838;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btnProfileEdit {
    /** font **/
    color: #898989;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    border: none;
    background: #EADDFF;
    transition: all 0.2s;
}

.btnProfileEdit:hover {
    background: #7B61FF;
}

.profileContainerFirst {
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: row;
    justify-content: space-between; /* 컴포넌트를 양쪽 끝에 배치 */
    align-items: center; /* 수직 중심 정렬 */
    padding: 10px 15px;
}

.profilePhoto {
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    border-radius: 1000px;
    border: 1px solid #C1C1C1;
}

.profileInfo {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: 300px;
}

.profileInfoElement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileInfoElementNum {
    color: #383838;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profileInfoElementText {
    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profileContainerSecond {
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: row;
    align-items: center; /* 수직 중심 정렬 */
    padding: 10px 23px;
}

.profileContainerThird {
    display: flex; /* Flexbox 레이아웃 사용 */
    justify-content: space-between; /* 컴포넌트를 양쪽 끝에 배치 */
    align-items: center; /* 수직 중심 정렬 */
    padding: 10px 23px;
    white-space: pre-wrap;
    color: #383838;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 114.286% */
}

.profileContainer {
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: column;
    margin-top: 10px;
}

.profileContainerTop {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
}

.profileContainerTopContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
}