.container {
    min-width: 340px !important;
    flex-direction: column;
    padding: 20px 24px;
    text-align: left;
    font-size: 14px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d9d9d9;
    display: flex;
    box-sizing: border-box;
}

.infoContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
}

.infoDetailContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: center;
}

.albumImg {
    height: 100px;
    width: 100px;
    border-radius: 4px;
}

.authorContainer {
    display: flex;
    flex-direction: row;
    gap: 7px;
    font-size: 14px;
    align-items: center;
}

.authorProfileImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.albumName {
    font-size: 24px;
    font-weight: 600;
}

.albumArtist {
    font-size: 14px;
    color: #A0A1A4;
}

.playlistTitle {
    font-size: 20px;
    font-weight: 600;
}

.likeContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    font-size: 12px;
}

.likeContainer > img {
    width: 20px;
    height: 20px;
}

.headerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    margin-bottom: 10px;
}

.footerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    justify-self: flex-end;
    margin-bottom: 10px;
}

.reviewContent {
    font-size: 20px;
    flex: 1;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: Roboto;
    color: #383838;
    position: relative;;
    text-align: left;
    display: flex;
    align-items: center;
}


.reviewDate {
    font-size: 12px;
    color: #A0A1A4;
}
