.profileContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profileNavBar {
    margin-top: 15px;
    margin-bottom: 7px;
    padding: 0 54px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.activeTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.indicator {
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 5px;
    transition: all 0.3s;
}

.activeTab .indicator {
    width: 30px;
    height: 3px;
    background: #7B61FF; /* 보라색 */
    border-radius: 5px;
}

.TabSection {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;

}

.subSection {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.sectionTitle {
    color: #808080;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sectionTitleContainer {
    display: flex;
    justify-content: space-between;
}

.btnEditTopster {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    padding: 2px 15px;
    height: 26px;
    box-sizing: border-box;
    border-radius: 13px;
    color: var(--color-black);
    background-color: var(--color-secondary);
    justify-content: flex-end;
}

