.logo {
    height: 30px;
    width: 110px;
    flex-shrink: 0;
    position: relative;
    display: inline-block;
}

.profileBtn {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid #C1C1C1;
}

.loginButton {
    padding: 12px 24px;
    height: fit-content;
    background-color: #65558F;
    color: white;
    border-radius: 8px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.profileImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.headerContainer {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    display: flex;
    background-color: white;
    z-index: 9999;
    border-bottom: 1px solid var(--color-gainsboro);
}

.headerWrapper {
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}