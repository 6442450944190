.reviewContainer {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px; /* Add padding for better spacing */
    width: 100%; /* Ensure full width */
    max-width: 800px; /* Set a max width for readability */
    margin: 0 auto; /* Center the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.writerName {
    color: #383838;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.writerPhoto {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 30px;
    background: url("/public/defaultProfile.svg") lightgray 50% / cover no-repeat;
}

.reviewWriter {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align components to the right */
    align-items: center; /* Center vertically */
    gap: 10px; /* Add a gap between components */
    margin-top: 30px;
}

.albumArt {
    margin-top: 5px;
    width: 120px;
    height: 120px;
}

.albumTitle {
    max-width: 100%;
    min-height: 40px;
    color: #383838;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.artist {
    min-height: 16px;
    color: #787878;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rating {
    margin-top: 20px;
}

.reviewInfo {
    margin-left: 20px; /* Remove left margin */
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.dates {
    margin-top: 10px;
    align-self: flex-end;
    color: #A0A1A4;
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.reviewCover {
    width: 100%;
    height: 130px;
    margin-top: 17px;
    display: flex;
    flex-direction: row;
}

.reviewTitle {
    width: 100%;
    margin-top: 20px;
    min-height: 52px;
    color: #383838;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.reviewContent {
    margin-bottom: 30px;
    color: #383838;
    text-align: justify;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px; /* 143.75% */
    width: 100%;
    max-width: 800px; /* Ensure content width does not exceed container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.reivewNav {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.likeIcon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-right: 450px;
}

.socialCount {
    color: #383838;
    margin-left: 7px;
    margin-top: -2px;
}

.shareIcon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.btnWrite {
    display: block; /* 버튼이 전체 너비를 차지하게 하여 중앙 정렬이 가능하도록 설정 */
    height: 51px;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    background: #7B61FF;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90%; /* 필요에 따라 너비 조절 */
    max-width: 550px; /* 가독성을 위해 최대 너비 설정 */
    margin: 30px auto; /* 버튼을 중앙에 배치하고 상단 마진 추가 */
    box-sizing: border-box; /* 패딩과 테두리를 요소의 전체 너비와 높이에 포함시킴 */
}

.btnContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 550px; /* Optional: Set a max width for readability */
    margin: 0 auto; /* Center the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.btnEdit {
    height: 51px;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    background: #7B61FF;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 40%;
    max-width: 550px; /* 가독성을 위해 최대 너비 설정 */
    margin: 30px auto; /* 버튼을 중앙에 배치하고 상단 마진 추가 */
    box-sizing: border-box; /* 패딩과 테두리를 요소의 전체 너비와 높이에 포함시킴 */
}

.btnDelete {
    display: block; /* 버튼이 전체 너비를 차지하게 하여 중앙 정렬이 가능하도록 설정 */
    height: 51px;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    background: #EADDFF;
    color: #383838;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90%; /* 필요에 따라 너비 조절 */
    max-width: 550px; /* 가독성을 위해 최대 너비 설정 */
    margin: 30px auto; /* 버튼을 중앙에 배치하고 상단 마진 추가 */
    box-sizing: border-box; /* 패딩과 테두리를 요소의 전체 너비와 높이에 포함시킴 */
}



.sectionTitle{
    color: #808080;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sectionTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subSection {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
}


.othersContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    max-width: 800px; /* Optional: Set a max width for readability */
    margin: 0 auto; /* Center the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.verticalScroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 500px; /* Adjust this as needed */
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Additional styling for better appearance */
h2 {
    margin: 0;
}

.toggleContainer .menu {
    display: flex;
    gap: 10px; /* Adjust this as needed */
}
