.dialogContainer {
    width: 90vw;
    max-width: 300px;
    background-color: white;
    border-radius: 20px;
    border: none;
    
}

.header {
    display: flex;
    font-size: 16px;
    color: #383838;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.closeButton {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

.body {
    display: flex;
    padding: 10px 0px;
}

.linkBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 12px;
    border: 1px solid grey;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 12px;
    box-sizing: border-box;
}

.linkText {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    color: #383838;
}

.copyButton {
    height: 24px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    background-color: var(--color-primary);
    color: white;
    border-radius: 12px;
    box-sizing: border-box;
}

