.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialogContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.modal {
    background-color: white;
    width: 90%;
    box-sizing: border-box;
    height: 80%;
    max-width: 540px;
    border-radius: 10px;
    flex-direction: column;
    padding: 0;
}

.modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    position: relative;
    box-sizing: border-box;
}

.modalTitle {
    position: absolute;
    font-size: 18px;
    font-family: Roboto;
    color: #000;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
}

.closeButton {
    position: absolute;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    box-sizing: border-box;
}

.modalBody {
    flex: 1;
    flex-direction: column; /* 요소들을 세로 방향으로 배치 */
    align-items: flex-start; /* 왼쪽 정렬 */
    margin: 30px;
    display: flex;
    overflow: hidden;
}

.playlistContainer {
    display: flex;
    flex-direction: column; /* 요소들을 세로 방향으로 배치 */
    align-items: flex-start; /* 왼쪽 정렬 */
    margin-bottom: 20px; /* 아래쪽 요소와의 간격 */
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    overflow: scroll;
}

.playlistContainer {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.playlistContainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.newList, .playlistItem {
    width: 100%; /* 컨테이너 전체 너비를 사용 */
    display: flex;
    align-items: center; /* 텍스트와 이미지 세로 중앙 정렬 */
    margin-bottom: 20px; /* 각 아이템 아래의 간격 */
}

.listadd {
    margin-right: 10px; /* 이미지와 텍스트 간 간격 */
    width: 85px;
    height: 85px;
}

.albumArt {
    width: 85px; /* 앨범 커버 이미지 크기 */
    height: 85px;
    margin-right: 10px; /* 옆 텍스트와의 간격 */
    border-radius: 5px; /* 앨범 커버 모서리 둥글게 */
}

/* 체크박스 숨기기 */
.hiddenCheckbox {
    display: none;
}

/* 사용자 정의 체크박스 레이블 */
.checkboxLabel {
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #D9D9D9; /* 보라색으로 설정 */
    border-radius: 5px; /* 둥근 모서리 */
    position: relative;
    transition: background 0.3s; /* 부드러운 배경색 전환 효과 */
    margin-left: auto; /* 체크박스를 오른쪽 끝으로 정렬 */
}

/* 체크될 때의 스타일 */
.checked::after {
    content: '';
    position: absolute;
    left: 12px;
    top: 2px;
    width: 13px;
    height: 24px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(40deg);

}

/* 체크될 때의 배경색 변경 */
.checked {
    background-color: #7b61ff; /* 체크 상태에서 진한 보라색 */
}

.addListContainer {

}

.musicAddButton {
    background-color: #7b61ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: Roboto;
    width: 90%;
    align-self: center;
    margin-top: auto;
    margin-bottom: 20px;
}
  
  
