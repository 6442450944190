.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    width: 90%;
    height: 80%;
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid #ccc;
    position: relative;
}

.modalTitle {
    position: absolute;
    font-size: 18px;
    font-family: Roboto;
    color: #000;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
}

.searchContainer {
    position: relative;
    padding: 1rem;
    margin-top: 10px;
}

.searchBar {
    width: 100%;
    font-size: 1rem;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: 2px solid var(--color-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 30px;

}

.searchIcon {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}


.closeButton {
    position: absolute;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.listContainer {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;

}

.songItem {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;

}

.songCover {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 1rem;
}

.songInfo {
    display: flex;
    flex-direction: column;
}

.songTitle {
    font-weight: bold;
}

.songArtist {
    color: #666;
}

.suggest {
    margin-left: 10px;
    font-weight: bold;

}
