.profilePhoto {
    position: relative;
    width: 100px;
    height: 100px;
    background-image: url('/public/defaultProfile.svg'); /* 디폴트 이미지 경로 */
    background-size: cover;
    border-radius: 50%; /* 원 모양으로 만들기 */
    cursor: pointer; /* 마우스 오버 시 커서 변경 */
}

.photoSection {
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: column;
    margin-top: 50px;
    justify-content: center; /* 컴포넌트를 가운데에 배치 */
    align-items: center; /* 수직 중심 정렬 */
    gap: 20px; /* 컴포넌트 사이의 간격 */
}

.profileName {
    color: #383838;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 360px;
    height: 40px;
    flex-shrink: 0;
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: row;
    gap: 20px;
}

.profileBio {
    color: #383838;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 360px;
    height: 160px;
    flex-shrink: 0;
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: row;
    gap: 20px;
}

.textSection {
    margin-left: 30px;
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: column;
    gap: 20px; /* 컴포넌트 사이의 간격 */

}

.textInput {
    width: 260px; /* 전체 너비를 사용하도록 설정 */
    height: 20px; /* 높이 설정 */
    padding: 8px; /* 내부 여백 설정 */
    border: 1px solid #ccc; /* 테두리 설정 */
    border-radius: 4px; /* 테두리 둥글게 */
    resize: none; /* 크기 조절 기능 비활성화 */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
}

.textArea {
    width: 260px; /* 전체 너비를 사용하도록 설정 */
    height: 100px; /* 높이 설정 */
    padding: 8px; /* 내부 여백 설정 */
    border: 1px solid #ccc; /* 테두리 설정 */
    border-radius: 4px; /* 테두리 둥글게 */
    resize: none; /* 크기 조절 기능 비활성화 */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
}

.btnProfileEdit {
    /** font **/
    color: #898989;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    min-width: 64px;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    border: none;
    background: var(--Schemes-Primary-Container, #EADDFF);
    transition: all 0.2s;
}

.btnProfileEdit:hover {
    background: #7B61FF;
}

.editProfile {
    display: flex; /* Flexbox 레이아웃 사용 */
    flex-direction: column;
    justify-content: center; /* 컴포넌트를 가운데에 배치 */
    align-items: center; /* 수직 중심 정렬 */
    gap: 20px; /* 컴포넌트 사이의 간격 */
}