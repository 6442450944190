.container {
    width: 340px !important;
    height: 309px;
    flex-shrink: 0;
    flex-direction: column;
    gap: 15px;
    text-align: left;
    font-size: 14px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d9d9d9;
    padding: 20px 20px;
    box-sizing: border-box;  
    position: relative; 
}

.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box; 
    width: 100%;
}

.reviewContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    gap: 10px;
}

.footerContainer {
    width: 310px;
    display: flex;
    justify-content: space-between;
    position: absolute; /* 추가된 부분 */
    bottom: 15px; 
}

.albumImg {
    margin-top: 10px;
    height: 100px;
    width: 100px;
    border-radius: 4px;
    flex-shrink: 0;
}

.infoDetailContainer {
    margin-left: 19px;
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.authorContainer {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* 컴포넌트를 오른쪽 정렬합니다 */
    align-items: center; /* 수직축에서 컴포넌트를 중앙에 놓습니다 */
    gap: 10px; /* 컴포넌트 사이에 15px의 간격을 둡니다 */
}

.authorName {    
    color: #383838;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.authorProfileImg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 50%;
}

.albumName {
    font-size: 20px;
    font-weight: 600;
    font-family: Roboto;
    color: #383838;
    text-align: left;
    white-space: nowrap; /* 텍스트가 줄 바꿈 없이 한 줄에 유지되도록 합니다 */
    overflow: hidden; /* 넘치는 텍스트를 숨깁니다 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 생략 부호(...)로 표시합니다 */
}

.albumArtist {
    color: #787878;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.reviewContent{
    min-height: 86px;
}

.reviewTitle {
    color: #383838;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.content {
    color: #383838;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px;
}
.add {
    color: #C3C3C3;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.likeContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.likeCounter{
    color: #383838;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.reviewDate {
    color: #A0A1A4;
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
}
