.footerItemDesc {
    display: flex;
    flex-direction: row;
    text-align: center;
    color: #383838;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footerItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerItemContainer > img {
    width: 24px;
    height: 24px;
    padding: 3px;
}


.footer {
    display: flex;
    background: white;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    max-width: 600px;
    left: 0;
    height: 60px;
    bottom: 0;
    z-index: 999;
}

.footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 9999;
}