.container {
    display: flex;           /* Flexbox 레이아웃 사용 */
    flex-direction: row;       /* 요소들이 다음 행으로 넘어갈 수 있게 설정 */
    gap: 5px;          /* 요소 사이의 간격을 5px로 설정 */
    overflow-x: scroll;        /* 가로 스크롤바 활성화 (필요시) */          /* 컨테이너의 내부 여백 */
    justify-content: start; /* 요소들을 왼쪽 정렬 */
    max-height: 300px;
}

.container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}