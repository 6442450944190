@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");

body {
    margin: 0;
    max-width: 600px !important;
    position: relative;
    background-color: #fff;
    min-height: 100vh;
    width: 100%;
}

html {
    position: relative;
    background-color: #a0a1a4;
    display: flex;
    justify-content: center;
}

.frameWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
}

.verticalScroll {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 14px;
}

.verticalScroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

:root {
    /* fonts */
    --font-roboto: Roboto;
    --font-inter: Inter;

    /* font sizes */
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-base: 16px;
    --font-size-5xl: 24px;
    --font-size-xl: 20px;
    --font-size-3xs: 10px;
    --font-size-5xl-2: 24.2px;

    /* Colors */
    --color-white: #fff;
    --color-black: #383838;
    --color-darkgray: #a0a1a4;
    --color-primary: #7b61ff;
    --color-secondary: #EADDFF;
    --color-gainsboro: #d9d9d9;
    --color-gold: #ffc700;
    --color-silver: #c3c3c3;

    /* Gaps */
    --gap-10xl: 29px;
    --gap-smi: 13px;
    --gap-5xs: 8px;
    --gap-xs: 12px;
    --gap-6xs: 7px;
    --gap-xl: 20px;
    --gap-lgi: 19px;
    --gap-4xs: 9px;
    --gap-4xl: 23px;
    --gap-9xs: 4px;
    --gap-base: 16px;
    --gap-2xs: 11px;
    --gap-8xs: 5px;
    --gap-40xl: 59px;
    --gap-37xl: 56px;

    /* Paddings */
    --padding-smi: 13px;
    --padding-xs: 12px;
    --padding-sm: 14px;
    --padding-base: 16px;
    --padding-6xs: 7px;
    --padding-4xs-6: 8.6px;
    --padding-10xs: 3px;
    --padding-2xs: 11px;
    --padding-10xs-2: 2.2px;
    --padding-7xs: 6px;
    --padding-8xs: 5px;
    --padding-18xl: 37px;
    --padding-lgi: 19px;
    --padding-11xl: 30px;
    --padding-lg: 18px;
    --padding-base-6: 15.6px;
    --padding-10xl: 29px;
    --padding-9xs: 4px;
    --padding-11xs: 2px;
    --padding-8xl: 27px;
    --padding-4xs: 9px;
    --padding-38xl: 57px;
    --padding-12xs: 1px;
    --padding-54xl: 73px;
    --padding-52xl: 71px;
    --padding-5xs-5: 7.5px;
    --padding-mid: 17px;
    --padding-3xs: 10px;
    --padding-44xl: 63px;
    --padding-111xl: 130px;
    --padding-xl: 20px;

    /* Border radiuses */
    --br-3xs: 10px;
    --br-8xs: 5px;
    --br-9xs: 4px;
}
