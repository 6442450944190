.container {
    width: 340px !important;
    height: 188px;
    flex-shrink: 0;
    flex-direction: column;
    gap: 15px;
    text-align: left;
    font-size: 14px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d9d9d9;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;   
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


.writerContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: right;
    margin-bottom: 10px;
}

.authorContainer {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* 컴포넌트를 오른쪽 정렬합니다 */
    align-items: center; /* 수직축에서 컴포넌트를 중앙에 놓습니다 */
    gap: 10px; /* 컴포넌트 사이에 15px의 간격을 둡니다 */
}

.authorName {    
    color: #383838;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.authorProfileImg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 50%;
}

.songContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: left;
    margin-bottom: 10px;
}

.songTitle{
    color: #383838;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.songArtist{
    color: #787878;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.reviewContainer {
    width: 300px;
    color: #383838;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footerContainer {
    width: 310px;
    display: flex;
    justify-content: space-between;
    position: absolute; /* 추가된 부분 */
    bottom: 15px; /* 추가된 부분 */ 
}

.likeContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.likeCounter{
    color: #383838;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.likeContainer > img {
    width: 20px;
    height: 20px;
}

.reviewDate {
    color: #A0A1A4;
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
}