.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    backdrop-filter: blur(5px); /* 블러 효과 */
    z-index: 1000; /* 화면 최상위에 위치 */
}

.modalContent {
    background-color: #F5EEFF;
    width: 300px;
    height: 380px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
   
    z-index: 1001; /* 모달 내용이 더 높은 z-index를 가짐 */
}

.modalHeader{
    display: flex;
    
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    align-self: stretch;

    color: #383838;

}

.modalName{
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 80px;
}

.modalCloseBtn {
    background: url('/public/close.svg') no-repeat;
    width: 24px;
    height: 24px;
    border: none;
}

.albumCover{
    margin-top: 15px;
    width: 270px;
    height: 80px;
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.albumCoverImg{
    width: 80px;
    height: 80px;
}

.albumName{
    color: #383838;
    margin-top: 10px;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.albumArtist{
    color: #383838;
    
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.albumInfo{
    display: flex;
    flex-direction: column;
    gap: 11px;

}

.starRating{
    margin-top: 21px;
    width: 270px;
    height: 64px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.review{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.starName{
    color: #383838;
    
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.reviewName{
    color: #383838;
    
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.reviewTitle{
    display: flex;
    width: 270px;
    margin-top: 10px;
    padding: 10px;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    border: none;
    background: #FFF;
    color: #383838;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.reviewContent{
    display: flex;
    width: 270px;
    height: 140px;
    margin-top: 10px;
    padding: 10px;
    align-items: center;
    
    align-self: stretch;
    border-radius: 10px;
    border: none;
    background: #FFF;
    color: #383838;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.submitBtn{
    display: flex;
    width: 290px; /* 너비 설정 */
    height: 40px;
    padding: 10px;
    margin-top: 21px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    border: none;
    background: #7B61FF;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.reviewContent{
    width: 270px;
}

.reviewForm{
    width: 270px;
    display: flex;
    flex-direction: column;
}