.frameGroup,
.frameParent {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
}

.frameGroup {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 0 var(--padding-8xl);
    gap: var(--gap-xs);
}

.mutopiaInfo {
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 13px;
    background-size: cover;
    border-radius: 16px;
    padding: 10px 12px;
    isolation: isolate;
    position: relative;
    justify-content: center;
}

.mutopiaInfo::after {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: white;
    opacity: 0.4;
}

.mutopiaDescription {
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    margin: 10px 0 10px 0;
}

.mutopiaDetail {
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 1.5;

}

.mutopiaLogo {
    width: 90%;
    height: 100px;
    margin-top: 50px;
}

h1 {
    position: relative;
    display: inline-block;
    margin: 0;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
    min-width: 73px;
}

.div1 {
    min-width: 30px;
    z-index: 1;
}

.frameItem {
    width: 30px;
    height: 3px;
    position: relative;
    border-radius: var(--br-8xs);
    background-color: var(--color-mediumslateblue);
}

.sectionTitle {
    color: #808080;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sectionTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.homeSection {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.home {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    flex-direction: column;
    gap: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--color-black);
    font-family: var(--font-roboto);
    padding: 0px 24px
}
