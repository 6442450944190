.searchBox {
    width: 90%;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: 2px solid var(--color-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 18px;
    box-sizing: border-box;

}

.searchBoxInput {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 12px;
    font-size: 16px;
}

.searchBoxClear {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.searchBoxLoading {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resultSectionTitle {
    color: #383838;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
}

.resultContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 30px 0px 30px;
    width: 100%;
    box-sizing: border-box;
}

.typeKeyword {
    color: #A0A1A4;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 35px
}

.searchResults {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.resultItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.resultItemImg {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 9px;
    background-color: grey;
}

.resultItemName {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-black);
}

.resultItemArtist {
    font-size: 12px;
    color: #A0A1A4;
}

.svgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.svg-loader {
    display: flex;
    position: relative;
    align-content: space-around;
    justify-content: center;
    align-items: center;
}

.loaderSvgBg, .loaderSvgAnimate {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke: var(--color-primary);
}

.loaderSvgBg {
    stroke-width: 8px;
    stroke: rgb(207, 205, 245);
}

.loaderSvgAnimate {
    stroke-dasharray: 242.6;
    animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
    0% {
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 141.3;
        stroke-dashoffset: 141.3;
    }
    100% {
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 282.6;
    }
}