.playlist {
    margin: 25px;
}

.header {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.backIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    align-self: flex-start;
}

.detailsContainer {
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.playlistTitle {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-bottom: 10px;
}

.authorContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.authorProfileImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.likeButton {
    margin-top: 10px;
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.playButton {
    margin-top: 15px;
    width: 50px;
    cursor: pointer;
}

.addButton {
    background-color: transparent;
    color: black;
    border: 3px solid #65558F;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    flex: 1;
    justify-self: center;
    align-self: center;
}

.buttonContainer {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
}

.reviseContainer{
    display: flex;
    justify-content: space-between;

}

.listContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.description {
    margin-bottom: 20px;
}

.authorAndControlsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.controlsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    margin-top: 30px;
}

.revise {
    cursor: pointer;
    width: 20px;
}
