.columnContainer{
    display: flex;
    flex-direction: column;
}

.container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

