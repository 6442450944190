.container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 0 0;
}

.menu {
    display: flex;
    flex-direction: column;
    color: #A0A1A4;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.focus {
    color: #383838;
}

.focus::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    border-radius: 100px;
    margin-top: 1px;
}