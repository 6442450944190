.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.modalContainer {
    background-color: #F5EEFF;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center; /* 모든 내용을 세로 방향으로 중앙 정렬 */
    position: relative;
    align-items: center; /* 모든 내용을 가로 방향으로 중앙 정렬 */
    display: flex;
}

.modal {
    background-color: #F5EEFF;
    width: 90%;
    max-width: 540px;
    border-radius: 10px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center; /* 모든 내용을 세로 방향으로 중앙 정렬 */
    position: relative;
    align-items: center; /* 모든 내용을 가로 방향으로 중앙 정렬 */
}

.heading1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center; /* 텍스트 중앙 정렬 */
    font-weight: bold;
    font-size: 20px;
}

.heading2 {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center; /* 텍스트 중앙 정렬 */
    font-size: 20px;
    font-weight: bold;
}

.input {
    width: 80%;
    padding: 12px;
    font-size: 16px;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid #7B61FF;
    text-align: center; /* 입력 필드 텍스트 중앙 정렬 */
}

.button {
    width: 80%;
    padding: 15px 20px;
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #7B61FF;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: white;
}

.button:hover {
    background-color: #8863ff;
}


.modalHeader {
    width: 100%; /* 헤더의 전체 너비 사용 */
    display: flex;
    box-sizing: border-box;
    justify-content: space-between; /* 요소들을 양 끝으로 정렬 */
    align-items: center; /* 세로 방향 중앙 정렬 */
    padding: 2rem;
    position: relative;

}

.modalTitle {
    position: absolute;
    font-size: 18px;
    font-family: Roboto;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
    margin-right: 20px;
}
  
  