.loginModal {
    background-color: white;
    width: 100%;
    max-width: 400px;
    position: sticky;
    border: none;
    border-radius: 10px 10px 0 0;
    background-color: #F5EEFF;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
}

.loginHeader {
    display: flex;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loginItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 9px;
}

.loginItemIcon1 {
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.loginItemIcon2 {
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-left: -16px;
}

.loginItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 29px;
}